import React, { useCallback, useState, useEffect } from 'react';
import { ScrollView, FlatList, Text, Spinner, VStack, HStack, Button, Center, Box, Modal } from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import * as TrainingActions from 'src/redux/actions/trainingAction';
import * as UploadAction from 'src/redux/actions/dataset';
import { IStore } from 'src/redux/reducers';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';
import { Dataset } from 'src/models/UploadImages';

const CreateTrainingJobPage = ({ navigation }) => {
  const dispatch = useDispatch();
  const selectedGrainId = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const { datasets = [], loading } = useSelector<IStore, { datasets: any[], loading: boolean }>((state) => state.dataset);
  const currentTrainingJob = useSelector((state: IStore) => state.training.currentTrainingJob); // Access the current job

  const [selectedDatasetIds, setSelectedDatasetIds] = useState<string[]>([]);
  const [filteredDatasets, setFilteredDatasets] = useState<Dataset[]>([]);
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useFocusEffect(
    useCallback(() => {
      let isActive = true;

      const fetchData = async () => {
        if (!hasRenderedOnce) {
          try {
            await dispatch(UploadAction.fetchDatasetInfo.request());
            if (isActive) {
              setHasRenderedOnce(true);
            }
          } catch (error) {
            if (isActive) {
              console.error('Failed to fetch dataset info:', error);
            }
          }
        }
      };

      fetchData();

      return () => {
        isActive = false;
      };
    }, [dispatch, hasRenderedOnce])
  );

  const handleCreateTrainingJob = useCallback(async () => {
    if (selectedGrainId && selectedGrainId !== "all") {
      try {
        await dispatch(TrainingActions.createTrainingJob.request({
          grainId: selectedGrainId,
          datasetIds: selectedDatasetIds,
        }));
        setShowModal(true);
      } catch (error) {
        console.error('Failed to create training job:', error);
      }
    }
  }, [selectedGrainId, selectedDatasetIds, dispatch]);

  const handleDatasetSelect = useCallback((datasetId: string) => {
    setSelectedDatasetIds(prev => 
      prev.includes(datasetId) 
        ? prev.filter(id => id !== datasetId)
        : [...prev, datasetId]
    );
  }, []);

  const handleSelectAll = useCallback(() => {
    if (selectedDatasetIds.length === filteredDatasets.length) {
      setSelectedDatasetIds([]);
    } else {
      setSelectedDatasetIds(filteredDatasets.map(dataset => dataset.datasetID));
    }
  }, [filteredDatasets, selectedDatasetIds]);

  useEffect(() => {
    const filtered = selectedGrainId === "all"
      ? datasets
      : datasets.filter(dataset => dataset.grainId === selectedGrainId);

    filtered.sort((a, b) => b.createdAt - a.createdAt);

    setFilteredDatasets(filtered);
  }, [datasets, selectedGrainId]);

  const renderItem = useCallback(({ item }: { item: Dataset }) => (
    <HStack justifyContent="space-between" alignItems="center" mb={2}>
      <Text fontWeight="bold">{item.datasetID}</Text>
      <Button
        onPress={() => handleDatasetSelect(item.datasetID)}
        variant={selectedDatasetIds.includes(item.datasetID) ? "solid" : "outline"}
      >
        {selectedDatasetIds.includes(item.datasetID) ? "Selected" : "Select"}
      </Button>
    </HStack>
  ), [handleDatasetSelect, selectedDatasetIds]);

  const handleCopyToClipboard = useCallback(() => {
    if (currentTrainingJob?.trainingJobID) {
      navigator.clipboard.writeText(currentTrainingJob.trainingJobID);
    }
  }, [currentTrainingJob]);

  if (!hasRenderedOnce) {
    return null;
  }

  if (loading) {
    return (
      <Center flex={1} px="3">
        <Spinner accessibilityLabel="Loading datasets" />
        <Text mt={4}>Loading datasets...</Text>
      </Center>
    );
  }

  return (
    <ScrollView>
      <VStack space={4} p={5}>
        <Text fontSize="xl" fontWeight="bold">Create Training Job</Text>
        <Box>
          <Text mb={2}>Select Grain Type:</Text>
          <GrainTypeDropdown all />
        </Box>

        {filteredDatasets.length === 0 ? (
          <VStack space={4} alignItems="center">
            <Text>No labeled datasets available. Please label the existing datasets.</Text>
            <Button onPress={() => navigation.navigate('DatasetListingPage')}>
              Go to Dataset Listing Page
            </Button>
          </VStack>
        ) : (
          <>
            <HStack justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="semibold">Select Datasets:</Text>
              <Button onPress={handleSelectAll}>
                {selectedDatasetIds.length === filteredDatasets.length ? "Deselect All" : "Select All"}
              </Button>
            </HStack>

            <FlatList
              data={filteredDatasets}
              renderItem={renderItem}
              keyExtractor={(item) => item.datasetID}
            />

            <Button
              onPress={handleCreateTrainingJob}
              isDisabled={selectedGrainId === "all"}
            >
              Create Training Job
            </Button>
          </>
        )}
      </VStack>

      {showModal && currentTrainingJob && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <Center flex={1}>
            <Box p={5} bg="white" borderRadius="10">
              <Text fontSize="lg" fontWeight="bold">Training Job Created</Text>
              <Text mt={2}>Job ID: {currentTrainingJob.trainingJobID}</Text>
              <HStack mt={4} space={4}>
                <Button onPress={handleCopyToClipboard}>
                  Copy Job ID
                </Button>
                <Button onPress={() => setShowModal(false)}>
                  Close
                </Button>
              </HStack>
            </Box>
          </Center>
        </Modal>
      )}
    </ScrollView>
  );
};

export default CreateTrainingJobPage;
