import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as TestingJobActions from "../actions/testingAction";
import { createTestingJob as apiCreateTestingJob, fetchTestingJobDetails as apiFetchTestingJobDetails ,fetchAllTestingJobs} from "src/api/ml-training";
import { TestingJobInfo } from "src/models/testingJob";

function* createTestingJobSaga(action: ReturnType<typeof TestingJobActions.createTestingJob.request>) {
  try {
    const request = action.payload;
    const testingJob: TestingJobInfo = yield call(apiCreateTestingJob, request);
    yield put(TestingJobActions.createTestingJob.success(testingJob));
  } catch (error: any) {
    yield put(TestingJobActions.createTestingJob.failure(error.message));
  }
}

function* fetchTestingJobDetailsSaga(action: ReturnType<typeof TestingJobActions.fetchTestingJobDetails.request>) {
  try {
    const testingJobId = action.payload;
    const testingJob: TestingJobInfo = yield call(apiFetchTestingJobDetails, testingJobId);
    yield put(TestingJobActions.fetchTestingJobDetails.success(testingJob));
  } catch (error: any) {
    yield put(TestingJobActions.fetchTestingJobDetails.failure(error.message));
  }
}

function* fetchAllTestingJobsSaga() {
    try {
      const testingJobs: TestingJobInfo[] = yield call(fetchAllTestingJobs);
      yield put(TestingJobActions.fetchAllTestingJobs.success(testingJobs));
    } catch (error: any) {
      yield put(TestingJobActions.fetchAllTestingJobs.failure(error.message));
    }
  }

// Watchers
function* watchCreateTestingJob() {
  yield takeEvery(TestingJobActions.createTestingJob.request, createTestingJobSaga);
}

function* watchFetchTestingJobDetails() {
  yield takeEvery(TestingJobActions.fetchTestingJobDetails.request, fetchTestingJobDetailsSaga);
}

function* watchFetchAllTestingJobs() {
    yield takeEvery(TestingJobActions.fetchAllTestingJobs.request, fetchAllTestingJobsSaga);
  }

export default function* testingJobSaga() {
  yield all([
    fork(watchCreateTestingJob),
    fork(watchFetchTestingJobDetails),
    fork(watchFetchAllTestingJobs)
  ]);
}
