// src/redux/actions/testingJobAction.ts
import { createAsyncAction, createAction } from "typesafe-actions";
import { CreateTestingJobRequest, TestingJobInfo } from 'src/models/testingJob';

export const resetTestingJobs = createAction('RESET_TESTING_JOBS')<void>();

export const createTestingJob = createAsyncAction(
  'CREATE_TESTING_JOB_REQUEST',
  'CREATE_TESTING_JOB_SUCCESS',
  'CREATE_TESTING_JOB_FAILURE',
)<CreateTestingJobRequest, TestingJobInfo, string>();

export const fetchTestingJobDetails = createAsyncAction(
  'FETCH_TESTING_JOB_DETAILS_REQUEST',
  'FETCH_TESTING_JOB_DETAILS_SUCCESS',
  'FETCH_TESTING_JOB_DETAILS_FAILURE',
)<string, TestingJobInfo, string>();

export const fetchAllTestingJobs = createAsyncAction(
    'FETCH_ALL_TESTING_JOBS_REQUEST',
    'FETCH_ALL_TESTING_JOBS_SUCCESS',
    'FETCH_ALL_TESTING_JOBS_FAILURE',
  )<void, TestingJobInfo[], string>();
  