import { combineReducers } from 'redux';
import { StateType } from "typesafe-actions";

import { JobReducer } from './jobReducer'
import { GrainTypeReducer } from './grainTypeReducer'
import { GrainProfileReducer } from './grainProfileReducer'
import { UploadReducer } from './uploadReducer'
import { ProductReleaseReducer } from './productReleaseReducer'
import { BluetoothReducer } from './bluetoothReducer'
import { AppReducer } from './appReducer'
import { OrgReducer } from './orgReducer'
import { NotificationReducer } from './notification'
import { ReportReducer } from './reportReducer'
import { LoginReducer } from './loginReducer'
import { UserReducer } from './userReducer'
import { UserGuideReducer } from './userGuideReducer';
import { DatasetReducer } from './datasetReducer';
import { trainingReducer } from './trainingReducer';
import { testingJobReducer } from './testingReducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';

const rootReducer = combineReducers({
  job: JobReducer,
  grainType: GrainTypeReducer,
  grainProfile: GrainProfileReducer,
  upload: UploadReducer,
  app: AppReducer,
  org: OrgReducer,
  bluetooth: BluetoothReducer,
  notification: NotificationReducer,
  report: ReportReducer,
  login: LoginReducer,
  user: UserReducer,
  productRelease: ProductReleaseReducer,
  userGuide: UserGuideReducer,
  dataset: DatasetReducer,
  training: trainingReducer,
  testing: testingJobReducer
});

const persistConfig = {
  key: 'rootv2',
  version: 2,
  storage: AsyncStorage,
  whitelist: [
    'app',
    'grainType',
    'grainProfile',
    'notification',
    'org',
    'user'
  ]
};

export const pReducer = persistReducer(persistConfig, rootReducer);

export * from "./jobReducer";
export * from "./grainTypeReducer";
export * from "./grainProfileReducer";
export * from "./uploadReducer";
export * from "./appReducer";
export * from "./orgReducer";
export * from "./bluetoothReducer";
export * from "./notification";
export * from "./reportReducer";
export * from "./loginReducer";
export * from "./userReducer";
export * from "./productReleaseReducer";
export * from "./userGuideReducer";
export * from "./datasetReducer";
export * from "./trainingReducer";
export * from "./testingReducer";
export type IStore = StateType<typeof pReducer>;