import { customFetchWithRetry } from './shared';
import FetchError from './errors';
import {
  CreateTrainingJobRequest,
  TrainingJobInfo,
} from "../models/Training";
import { CreateTestingJobRequest, TestingJobInfo } from 'src/models/testingJob';

export const createTrainingJob = async (request: CreateTrainingJobRequest): Promise<string> => {
  try {
    const response = await customFetchWithRetry(`/ml/training`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error creating training job. ${await response.text()}`, response.status);
    }

    const responseData = await response.json();
    return responseData.trainingJobID;
  } catch (e) {
    console.log('Error creating training job', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const fetchTrainingJobDetails = async (trainingJobId: string): Promise<TrainingJobInfo> => {
  try {
    const response = await customFetchWithRetry(`/ml/training/${trainingJobId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error fetching training job details. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error fetching training job details', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const fetchAllTrainingJobs = async (): Promise<TrainingJobInfo[]> => {
  try {
    const response = await customFetchWithRetry(`/ml/training`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error fetching all training jobs. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error fetching all training jobs', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const updateTrainingJob = async (
  updatedData: any
): Promise<TrainingJobInfo> => {
  try {
    const response = await customFetchWithRetry(`/ml/training`, {
      method: 'PUT',
      body: JSON.stringify(updatedData),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error updating training job. ${await response.text()}`, response.status);
    }

    const updatedTrainingJob = await response.json();
    return updatedTrainingJob;
  } catch (e) {
    console.log('Error updating training job', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};


export const createTestingJob = async (request: CreateTestingJobRequest): Promise<TestingJobInfo> => {
  try {
    const response = await customFetchWithRetry(`/ml/testing`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error creating testing job. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error creating testing job', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const fetchTestingJobDetails = async (testingJobId: string): Promise<TestingJobInfo> => {
  try {
    const response = await customFetchWithRetry(`/ml/testing/${testingJobId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error fetching testing job details. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error fetching testing job details', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const fetchAllTestingJobs = async (): Promise<TestingJobInfo[]> => {
  try {
    const response = await customFetchWithRetry(`/ml/testing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error fetching all testing jobs. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error fetching all testing jobs', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};
