import * as React from 'react';
import { containerStyle } from 'src/styles/containerStyle';
import { Box } from 'native-base';

import MlStackTabs from 'src/components/ml/MlStackTabs';

export default function MlDashboardPage({ navigation }) {
    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <MlStackTabs navigation={navigation} />
        </Box>
    );
}