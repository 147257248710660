
import { customFetchWithRetry } from './shared';
import FetchError from './errors';
import { 
    CreateDatasetRequest,
    IImageWithLabel,
    DatasetInfo,
    ICompleteLabelingRequest,
    UpdateImageLabelsRequest
  } from "../models/UploadImages";
  
export const createDataset = async (request: CreateDatasetRequest): Promise<string> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error creating dataset. ${await response.text()}`, response.status);
      }
  
      const responseText = await response.text();
      const datasetID = responseText.split(' ').pop();
      
      if (!datasetID) {
        throw new Error('Failed to get dataset ID from response');
      }
  
      return datasetID;
  
    } catch (e) {
      console.log('Error creating dataset', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  export const uploadBatch = async (
    batch: IImageWithLabel[],
    datasetId: string,
    grainId: string,
    method: 'POST' | 'PUT',
  ): Promise<void> => {
    const requestBody = {
      datasetID: datasetId,
      grainId: grainId,
      imageList: batch,
    };
  
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new FetchError(`Failed to upload batch via ${method}. ${await response.text()}`, response.status);
      }
    } catch (e) {
      console.error(`Error uploading batch via ${method}:`, e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };

  export const fetchDatasetInfo = async (
    limit = 50,
    lastKey: string | null = null
  ): Promise<{ datasets: DatasetInfo[]; nextKey: string | null }> => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('limit', limit.toString());
      if (lastKey) {
        queryParams.append('lastKey', lastKey);
      }
  
      const response = await customFetchWithRetry(
        `/ml/dataset?${queryParams.toString()}`, 
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );
  
      if (!response.ok) {
        throw new FetchError(`Error fetching dataset info. ${await response.text()}`, response.status);
      }
  
      const data = await response.json();
      const datasetInfo = data.datasets.map((dataset: any) => ({
        datasetID: dataset.datasetID,
        status: dataset.status,
        grainId: dataset.grainId,
        username: dataset.username,
        createdAt: dataset.updatedAt
      }));
  
      return { datasets: datasetInfo, nextKey: data.nextKey };
    } catch (e) {
      console.log('Error fetching dataset info', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  export const fetchDatasetDetails = async (datasetId: string): Promise<ICompleteLabelingRequest> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset/${datasetId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error fetching dataset details. ${await response.text()}`, response.status);
      }
  
      return response.json();
    } catch (e) {
      console.log('Error fetching dataset details', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  
  export const fetchAllImagesFromS3 = async (datasetId: string, continuationToken: string | null = null) => {
    let allImageUrls: string[] = [];
    let isTruncated = true;
  
    try {
      while (isTruncated) {
        // Properly encode the continuationToken if it exists
        const queryString = continuationToken
          ? `?continuationToken=${encodeURIComponent(continuationToken)}`
          : '';
  
        const response = await customFetchWithRetry(`/ml/dataset/${datasetId}/image${queryString}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new FetchError(`Error fetching images. ${await response.text()}`, response.status);
        }
  
        const { imageUrls, isTruncated: nextIsTruncated, nextContinuationToken } = await response.json();
  
        allImageUrls = allImageUrls.concat(imageUrls);
        continuationToken = nextContinuationToken;
        isTruncated = nextIsTruncated;
      }
  
      return { imageUrls: allImageUrls };
    } catch (e) {
      console.error('Error fetching images', e);
      throw new FetchError((e as Error).message, 500);
    }
  };  
  
  

  export const updateImageLabelsApi = async (
    datasetId: UpdateImageLabelsRequest['datasetId'],
    changedLabels: UpdateImageLabelsRequest['changedLabels'],
    status: UpdateImageLabelsRequest['status'],
    grainId: UpdateImageLabelsRequest['grainId']
  ): Promise<void> => {
    const imageList = Object.entries(changedLabels).map(([imagePath, label]) => ({
      imagePath,
      label,
    }));
  
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'PUT',
        body: JSON.stringify({
          datasetID: datasetId,
          imageList,
          status,
          grainId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error completing tagging. ${await response.text()}`, response.status);
      }
    } catch (e : any) {
      console.log('Error completing tagging', e);
      throw e instanceof FetchError ? e : new FetchError(e.message, 500);
    }
  };
  
  export const getDatasetsByGrainId = async (
    grainID: string
  ): Promise<{ datasets: DatasetInfo[] }> => {
    try {
      if (!grainID || grainID === 'select') {
        throw new Error('Invalid grainID provided. Please select a valid grain.');
      }
  
      // Only use path parameter for grainID, no query params needed
      const endpoint = `/ml/dataset/grain/${encodeURIComponent(grainID)}`;
  
      const response = await customFetchWithRetry(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(
          `Error fetching datasets by grainID. ${await response.text()}`,
          response.status
        );
      }
  
      const data = await response.json();
  
      // Map the datasets
      const datasets = data.datasets.map((dataset: any) => ({
        datasetID: dataset.datasetID,
        status: dataset.status,
        grainId: dataset.grainId, // You can rename this to grainID if your backend is also returning "grainID"
        username: dataset.username,
        createdAt: dataset.updatedAt || dataset.createdAt,
      }));
  
      // Return just the datasets array now
      return { datasets };
    } catch (error) {
      console.error('Error fetching datasets by grainID:', error);
      throw error instanceof FetchError ? error : new FetchError((error as Error).message, 500);
    }
  };
  