import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as UploadAction from "../actions/dataset";
import { 
  initiateMultipartUpload, uploadParts, completeMultipartUpload 
} from "../../api/mlUpload";
import {
  createDataset,
  fetchDatasetInfo as fetchDatasetIdsApi,
  fetchDatasetDetails as fetchDatasetDetailsApi,
  updateImageLabelsApi,
  fetchAllImagesFromS3,
  getDatasetsByGrainId 
} from "../../api/ml-dataset";
import { getRejectionMapping } from "../../api/grain";
import { showErrorMessage } from "./shared";
import { IRejectionMapping, Image, CreateDatasetRequest } from "src/models/UploadImages";
import { LabelingStatus } from "src/models/LabelingStatus";
import {FetchDatasetInfoResponse } from "src/models/Training";

// Selectors
import { IStore } from "../reducers";
import { IImageWithLabel, IUploadJobImageRequest } from "src/models/UploadImages";

const CONCURRENT_UPLOADS = 10;
const MAX_DYNAMODB_ITEM_SIZE = 400 * 1024;

function* fetchRejectionMapping(action: ReturnType<typeof UploadAction.fetchRejectionMapping.request>) {
  try {
    const rejectionMappingFromApi: IRejectionMapping = yield call(getRejectionMapping, action.payload.grainId);
    const rejectionMapping: IRejectionMapping = { good: "Good Grains", na: "NA", ...rejectionMappingFromApi };
    yield put(UploadAction.fetchRejectionMapping.success(rejectionMapping));
  } catch (error:any) {
    yield put(UploadAction.fetchRejectionMapping.failure(error));
  }
}
function* getRejectionMappingWithDefaults(grainId: string): Generator<any, IRejectionMapping, any> {
  const rejectionMappingFromApi: IRejectionMapping = yield call(getRejectionMapping, grainId);
  const rejectionMapping: IRejectionMapping = { good: "Good Grains", na: "NA", ...rejectionMappingFromApi };
  return rejectionMapping;
}

function* fetchDatasetInfoSaga(action: ReturnType<typeof UploadAction.fetchDatasetInfo.request>): Generator<any, void, any> {
  try {
    const { limit = 50, lastKey = null } = action.payload;
    const grainIdFilter: string = yield select((state) => state.app.grainIdFilter);

    if (grainIdFilter && grainIdFilter !== 'all') {
      const rejectionMapping = yield call(getRejectionMappingWithDefaults, grainIdFilter);
      yield put(UploadAction.setRejectionMapping(rejectionMapping));
    } else {
      yield put(UploadAction.setRejectionMapping({}));
    }

    const response: FetchDatasetInfoResponse = yield call(fetchDatasetIdsApi, limit, lastKey);
    yield put(UploadAction.fetchDatasetInfo.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.fetchDatasetInfo.failure(error));
  }
}


function* fetchDatasetDetails(action: ReturnType<typeof UploadAction.fetchDatasetDetails.request>): Generator<any, void, any> {
  try {
    const { datasetId } = action.payload;
    if (!datasetId) {
      console.warn('fetchDatasetDetails called without datasetId.');
      return;
    }
    console.log('fetchDatasetDetails:', datasetId);
    const datasetDetails: { imageList: Image[] } = yield call(fetchDatasetDetailsApi, datasetId);
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const rejectionMapping: IRejectionMapping = yield call(getRejectionMappingWithDefaults, grainId);
    const s3ImageResponse = yield call(fetchAllImagesFromS3, datasetId);
    const s3ImageUrls: string[] = s3ImageResponse.imageUrls;

    const combinedImages: IImageWithLabel[] = datasetDetails.imageList.map(img => {
      const matchedUrl = s3ImageUrls.find(url => {
        const decodedUrl = decodeURIComponent(url);
        const urlPath = decodedUrl.split("?")[0];
        return urlPath.includes(img.imagePath);
      });

      return {
        ...img,
        imageUrl: matchedUrl ? matchedUrl : null,
      };
    });

    yield put(
      UploadAction.fetchDatasetDetails.success({
        ...datasetDetails,
        combinedImages,
        rejectionMapping,
      })
    );
  } catch (error: any) {
    yield put(UploadAction.fetchDatasetDetails.failure(error));
  }
}
function* getJobDetails(action: ReturnType<typeof UploadAction.getJobDetails.request>): Generator<any, void, any> {
  try {
    const { datasetId } = action.payload;
    const jobDetails = yield call(fetchDatasetDetailsApi, datasetId);
    yield put(UploadAction.getJobDetails.success(jobDetails));
  } catch (error:any) {
    yield put(UploadAction.getJobDetails.failure(error));
  }
} 


function* updateImageLabels(action: ReturnType<typeof UploadAction.updateImageLabels.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    yield call(updateImageLabelsApi, datasetId, changedLabels, status, grainId);
    yield put(UploadAction.updateImageLabels.success({ changedLabels, status, grainId }));
  } catch (error: any) {
    yield put(UploadAction.updateImageLabels.failure(error));
  }
}

export function* saveAsDraft(action: ReturnType<typeof UploadAction.saveAsDraft.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    if (!datasetId.startsWith('dataset_')) {
      yield call(createDatasetIfNotExists, datasetId, grainId, changedLabels);
    }
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.saveAsDraft.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.IN_PROGRESS));
  } catch (error : any){
    yield put(UploadAction.saveAsDraft.failure(error));
  }
}
function* fetchDatasetsByGrainIdSaga(action: { payload: string }): Generator<any, void, any> {
  try {
    const grainId = action.payload;
    const response = yield call(getDatasetsByGrainId, grainId);
    yield put(UploadAction.fetchDatasetsByGrainId.success(response.datasets));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.fetchDatasetsByGrainId.failure(error.message));
  }
}

export function* completeLabeling(action: ReturnType<typeof UploadAction.completeLabeling.request>): Generator<any, void, any> {
  try {
    const { datasetId, changedLabels, status, grainId } = action.payload;
    if (!datasetId.startsWith('dataset_')) {
      yield call(createDatasetIfNotExists, datasetId, grainId, changedLabels);
    }
    yield call(updateImageLabels, { payload: { datasetId, changedLabels, status, grainId } });
    yield put(UploadAction.completeLabeling.success());
    yield put(UploadAction.updateLabelingStatus(LabelingStatus.COMPLETED));
  } catch (error: any) {
    yield put(UploadAction.completeLabeling.failure(error));
  }
}
export function* createDatasetIfNotExists(datasetId: string, grainId: string, imageList: { [key: string]: string }): Generator<any, void, any> {
  try {
    if (!datasetId.startsWith('dataset_')) {
      const imageArray = Object.entries(imageList).map(([imagePath, label]) => ({
        imagePath,
        label,
      }));

      const request: CreateDatasetRequest = {
        datasetID: datasetId,
        imageList: imageArray,
        grainId,
        status: 'IN_PROGRESS',
        username: yield select((state: IStore) => state.user?.userProfile?.response?.username ?? ''),
      };

      const response = yield call(createDataset, request);
      yield put(UploadAction.setDatasetId(datasetId));
    }
  } catch (error) {
    console.error('Error creating dataset:', error);
    throw error;
  }
}

async function urlToFile(url: string, filename: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
}

export function* uploadConcurrently(action: ReturnType<typeof UploadAction.uploadML.request>): Generator<any, void, any> {
  const { files, datasetId, selectedLabel }: IUploadJobImageRequest = action.payload;
  const isExistingDataset: boolean = yield select((state: IStore) => state.dataset.isExistingDataset);
  const totalFiles = files.length;
  let uploadedFiles = 0;
  const uploadedImages: IImageWithLabel[] = [];

  yield put(UploadAction.setUploadProgress(0));

  if (isExistingDataset) {
    function* uploadExistingFile(file: File): Generator<any, void, any> {
      const grainId: string = yield select((state: IStore) => state.grainType.grainId);
      const uploadPath = `ml-images/${grainId}/${datasetId}`;
      const key = `${uploadPath}/${file}`;

      try {
        const { uploadId, presignedUrls } = yield call(initiateMultipartUpload, key, file.type);
        const parts = yield call(uploadParts, presignedUrls, file);
        yield call(completeMultipartUpload, key, uploadId, parts);

        uploadedFiles++;
        yield put(UploadAction.setUploadProgress(Math.round((uploadedFiles / totalFiles) * 100)));

        uploadedImages.push({
          imagePath: key,
          label: selectedLabel,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }

    const chunks = [];
    for (let i = 0; i < totalFiles; i += CONCURRENT_UPLOADS) {
      chunks.push(files.slice(i, i + CONCURRENT_UPLOADS));
    }

    for (const chunk of chunks) {
      yield all(chunk.map((file) => call(uploadExistingFile, file)));
    }
  } else {
    function* uploadNewFile(imagePath: string, label: string): Generator<any, void, any> {
      const grainId: string = yield select((state: IStore) => state.grainType.grainId);
      const uploadPath = `ml-images/${grainId}/${datasetId}`;
      const key = `${uploadPath}/${imagePath.split('/').pop()}`;

      try {
        const file = yield call(urlToFile, imagePath, imagePath.split('/').pop() || 'image.png');
        const { uploadId, presignedUrls } = yield call(initiateMultipartUpload, key, file.type);
        const parts = yield call(uploadParts, presignedUrls, file);
        yield call(completeMultipartUpload, key, uploadId, parts);

        uploadedFiles++;
        yield put(UploadAction.setUploadProgress(Math.round((uploadedFiles / totalFiles) * 100)));

        uploadedImages.push({
          imagePath: key,
          label: label
        });
      } catch (error) {
        console.error(`Error uploading file ${imagePath}:`, error);
      }
    }

    const chunks = [];
    const imageObjects = files.map((path, index) => ({
      path,
      label: Array.isArray(selectedLabel) ? selectedLabel[index] : selectedLabel
    }));

    for (let i = 0; i < imageObjects.length; i += CONCURRENT_UPLOADS) {
      chunks.push(imageObjects.slice(i, i + CONCURRENT_UPLOADS));
    }

    for (const chunk of chunks) {
      yield all(chunk.map(({path, label}) => call(uploadNewFile, path, label)));
    }
  }

  if (uploadedImages.length > 0) {
    yield call(uploadComplete, uploadedImages);
    yield put(UploadAction.uploadML.success("Upload successful"));
  } else {
    throw new Error("No images were successfully uploaded");
  }
}

function calculateSizeOfRequest(imageList: IImageWithLabel[]): number {
  return new Blob([JSON.stringify(imageList)]).size;
}


function* createDatasetInChunks(images: IImageWithLabel[], datasetId: string, grainId: string, username: string): Generator<any, void, any> {
  let currentChunk: IImageWithLabel[] = [];
  let currentSize = 0;
  let chunkIndex = 0;

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const imageSize = calculateSizeOfRequest([image]);

    if (currentSize + imageSize > MAX_DYNAMODB_ITEM_SIZE) {
      yield call(createDatasetChunk, currentChunk, datasetId, grainId, username, chunkIndex, i === images.length - 1);
      currentChunk = [];
      currentSize = 0;
      chunkIndex++;
    }

    currentChunk.push(image);
    currentSize += imageSize;
  }

  if (currentChunk.length > 0) {
    yield call(createDatasetChunk, currentChunk, datasetId, grainId, username, chunkIndex, true);
  }
}

function* createDatasetChunk(imageChunk: IImageWithLabel[], datasetId: string, grainId: string, username: string, chunkIndex: number, isLastChunk: boolean) {
  const chunkId = chunkIndex === 0 ? datasetId : `${datasetId}_chunk_${Date.now()}`;
  const request = {
    datasetID: chunkId,
    imageList: imageChunk,
    grainId,
    status: isLastChunk ? "NOT_LABELED" : "PARTIAL",
    username,
    isAppend: chunkIndex !== 0,
    parentDatasetId: chunkIndex === 0 ? null : datasetId,
  };

  try {
    const response = yield call(createDataset, request);
    console.log(`Created dataset chunk ${chunkIndex}:`, response);

    if (isLastChunk) {
      yield put(UploadAction.setUploadProgress(100));
    }
  } catch (error) {
    console.error(`Error creating dataset chunk ${chunkIndex}:`, error);
    throw error;
  }
}

export function* uploadComplete(uploadedImages: IImageWithLabel[]): Generator<any, void, any> {
  try {
    const datasetId: string = yield select((state: IStore) => state.dataset.datasetID);
    const grainId: string = yield select((state: IStore) => state.grainType.grainId);
    const username: string = yield select((state: IStore) => state.user?.userProfile.response?.username ?? '');

    if (!uploadedImages || !Array.isArray(uploadedImages) || uploadedImages.length === 0) {
      throw new Error('Images array is empty or invalid.');
    }

    yield call(createDatasetInChunks, uploadedImages, datasetId, grainId, username);
    yield put(UploadAction.setDatasetId(datasetId));
    yield put(UploadAction.setImages(uploadedImages));
  } catch (error: any) {
    console.error('Error in uploadComplete:', error);
    yield put(UploadAction.uploadML.failure(error.message));
  }
}
export function* initializeLabeling(action: ReturnType<typeof UploadAction.initializeLabeling.request>): Generator<any, void, any> {
  try {
    const datasetId = `dataset_${Date.now()}`;
    yield put(UploadAction.setDatasetId(datasetId));
    yield put(UploadAction.setUploadProgress(0));
    yield put(UploadAction.initializeLabeling.success());
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.initializeLabeling.failure(error));
  }
}

// Watchers
export function* watchUploadDataset() {
  yield takeEvery(UploadAction.uploadML.request, uploadConcurrently);
}

export function* watchInitializeLabeling() {
  yield takeEvery(UploadAction.initializeLabeling.request, initializeLabeling);
}

export function* watchCompleteLabeling() {
  yield takeEvery(UploadAction.completeLabeling.request, completeLabeling);
}
export function* watchRejections() {
  yield takeEvery(UploadAction.fetchRejectionMapping.request, fetchRejectionMapping);
}
export function* watchFetchDatasetDetails() {
  yield takeEvery(UploadAction.fetchDatasetDetails.request, function* (action) {
    console.log('Triggered watchFetchDatasetDetails:', action);
    yield call(fetchDatasetDetails, action);
  });
}

export function* watchSaveAsDraft() {
  yield takeEvery(UploadAction.saveAsDraft.request, saveAsDraft);
}

export function* watchFetchDatasetInfo() {
  yield takeEvery(UploadAction.fetchDatasetInfo.request, fetchDatasetInfoSaga);
}

export function* watchUpdateImageLabels() {
  yield takeEvery(UploadAction.updateImageLabels.request, updateImageLabels);
}
export function* watchGetJobDetails() {
  yield takeEvery(UploadAction.getJobDetails.request, getJobDetails)
}
function* watchFetchDatasetsByGrainId() {
  yield takeEvery(UploadAction.fetchDatasetsByGrainId.request, fetchDatasetsByGrainIdSaga);
}
export default function* rootSaga() {
  yield all([
    fork(watchUploadDataset),
    fork(watchInitializeLabeling),
    fork(watchCompleteLabeling),
    fork(watchFetchDatasetDetails),
    fork(watchSaveAsDraft),
    fork(watchFetchDatasetInfo),
    fork(watchUpdateImageLabels),
    fork(watchGetJobDetails),
    fork(watchRejections),
    fork(watchFetchDatasetsByGrainId)
  ]);
}
