import * as UploadAction from 'src/redux/actions/dataset';
import * as JobActions from 'src/redux/actions/jobAction';
import { LabelingStatus } from 'src/models/LabelingStatus';
import { Alert } from 'react-native';

import { Dispatch } from 'redux';
import { AnyAction } from 'redux';

export const fetchData = async (
  dispatch: Dispatch<AnyAction>,
  isJob: boolean,
  jobId: string | null,
  datasetId: string | null,
  isExistingDataset: boolean,
  setIsDataFetched: (fetched: boolean) => void
): Promise<void> => {
  if (!datasetId && !jobId) return;

  try {
    if (isJob) {
      if (!jobId) throw new Error('jobId is undefined');
      if (isExistingDataset) {
        await dispatch(UploadAction.fetchDatasetDetails.request({ datasetId: jobId }));
      } else {
        dispatch(JobActions.jobStatus.request(jobId));
      }
    } else {
      if (!datasetId) throw new Error('datasetId is undefined');
      await dispatch(UploadAction.fetchDatasetDetails.request({ datasetId }));
    }
  } catch (error: any) {
    console.error('Error in fetchData:', error);
    Alert.alert('Error', 'Failed to fetch data. Please try again.');
  } finally {
    setIsDataFetched(true);
  }
};

export const handleSaveAsDraft = async (
  dispatch,
  isJob,
  isExistingDataset,
  jobId,
  datasetId,
  grainId,
  changedLabels,
  images,
  navigation
) => {
  try {
    const updatedImages = images.map(img => ({
      ...img,
      label: changedLabels[img.imagePath] || img.label,
    }));
    console.log('Updated images:', updatedImages);

    const saveAsDraftRequest = {
      datasetId: isJob ? jobId! : datasetId!,
      changedLabels,
      status: LabelingStatus.IN_PROGRESS,
      grainId,
    };

    // Dispatch actions regardless of isJob
    await dispatch(UploadAction.setDatasetId(isJob ? jobId! : datasetId!));
    await dispatch(UploadAction.setImages(updatedImages));
    await dispatch(UploadAction.saveAsDraft.request(saveAsDraftRequest));

    navigation.navigate('DatasetListingPage', { refresh: true });
  } catch (error) {
    console.error('Error in handleSaveAsDraft:', error);
    Alert.alert('Error', 'Failed to save as draft. Please try again.');
  }
};

export const handleCompleteLabeling = async (
  dispatch,
  isJob,
  isExistingDataset,
  jobId,
  datasetId,
  grainId,
  changedLabels,
  images,
  navigation
) => {
  if (images.every(img => img.label || changedLabels[img.imagePath])) {
    const updatedImages = images.map(img => ({
      ...img,
      label: changedLabels[img.imagePath] || img.label,
    }));
    const completeLabelingRequest = {
      datasetId: isJob ? jobId : datasetId,
      changedLabels,
      status: LabelingStatus.COMPLETED,
      grainId,
      images: updatedImages
    };
    console.log('Complete labeling request:', completeLabelingRequest);
    dispatch(UploadAction.completeLabeling.request(completeLabelingRequest));
    console.log("isExistingDataset", isExistingDataset);
    if (!isExistingDataset) {
      dispatch(
        UploadAction.uploadML.request({
          files: updatedImages.map(img => img.imageUrl), // Use imageUrl instead of imagePath
          datasetId: jobId,
          grainId: grainId,
          selectedLabel: updatedImages.map(img => img.label)
        })
      );
    }

    navigation.navigate('DatasetListingPage', { refresh: true });
  } else {
    Alert.alert('Error', 'Please label all images before completing.');
  }
};