// src/redux/reducers/testingJobReducer.ts
import { ActionType, getType } from "typesafe-actions";
import * as TestingJobActions from '../actions/testingAction';
import { TestingJobInfo } from 'src/models/testingJob';

interface TestingJobState {
  testingJobs: TestingJobInfo[];
  currentTestingJob: TestingJobInfo | null;
  loading: boolean;
  error: string | null;
}

const initialTestingJobState: TestingJobState = {
  testingJobs: [],
  currentTestingJob: null,
  loading: false,
  error: null,
};

export const testingJobReducer = (
  state: TestingJobState = initialTestingJobState,
  action: ActionType<typeof TestingJobActions>
): TestingJobState => {
  switch (action.type) {
    case getType(TestingJobActions.createTestingJob.request):
    case getType(TestingJobActions.fetchTestingJobDetails.request):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getType(TestingJobActions.createTestingJob.success):
      return {
        ...state,
        loading: false,
        testingJobs: [...state.testingJobs, action.payload],
        currentTestingJob: action.payload,
      };
    case getType(TestingJobActions.fetchTestingJobDetails.success):
      return {
        ...state,
        loading: false,
        currentTestingJob: action.payload,
      };
    case getType(TestingJobActions.createTestingJob.failure):
    case getType(TestingJobActions.fetchTestingJobDetails.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getType(TestingJobActions.fetchAllTestingJobs.request):
    return {
        ...state,
        loading: true,
        error: null,
    };
    
    case getType(TestingJobActions.fetchAllTestingJobs.success):
    return {
        ...state,
        loading: false,
        testingJobs: action.payload,
    };
    
    case getType(TestingJobActions.fetchAllTestingJobs.failure):
    return {
        ...state,
        loading: false,
        error: action.payload,
    };      
    case getType(TestingJobActions.resetTestingJobs):
      return initialTestingJobState;
    default:
      return state;
  }
};
