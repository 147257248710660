import React, { useState, useEffect } from 'react';
import * as GrainTypeActions from "../../../redux/actions/grainTypeAction";
import * as DatasetActions from "../../../redux/actions/dataset";
import * as AppActions from "../../../redux/actions/appAction";
import { View } from 'react-native';
import { IStore } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { Select, Text } from "native-base";
import { textStyle } from 'src/styles/textStyle';
import { containerStyle } from 'src/styles/containerStyle';
import { selectProps } from 'src/styles/selectProps';
import {getText} from 'src/utils/i18n';

export interface IGrainTypeDropdownProps {
  all?: boolean; // if true, show "All grains" option and refers to Filter modal
}

export default function GrainTypeDropdown(props: IGrainTypeDropdownProps) {

  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
  const grainId: string | undefined = useSelector((store: IStore) => store.grainType.grainId);
  const grainIdFilter: string = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const dispatch = useDispatch();

  const [selectedGrainId, setSelectedGrainId] = useState<string | undefined>(props.all ? "all" : undefined);
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    setGrainTypeList();
  }, [grainTypes]);

  useEffect(() => {
    if (props.all) {
      setSelectedGrainId(grainIdFilter)
    } else {
      setSelectedGrainId(grainId)
    }
  }, [grainIdFilter, grainId]);

  const setGrainTypeList = () => {
    let items: any[] = [];

    if (!grainTypes) {
      return
    }

    grainTypes.forEach(item => {
      items.push({
        label: item['name'],
        value: item['grainId'],
      })
    });
    setTypes(items);
  }

  const onSelectedValueChanged = (value: any) => {
    if (!grainTypes) {
      return
    }

    if (props.all) {
      dispatch(AppActions.setGrainIdFilter(value));
    } else {
      dispatch(GrainTypeActions.setGrainId(value));
    }
    dispatch(DatasetActions.fetchRejectionMapping.request({ grainId: value }));
  };

  return (
    <View style={containerStyle.componentContainer}>
      <Text style={textStyle.subSectionText}>{getText('selectGrainType')}</Text>
      <Select width="100%"
        selectedValue={selectedGrainId}
        accessibilityLabel="Choose grain type"
        placeholder="Choose grain type"
        _selectedItem={selectProps.selectedItemProps}
        mt={1}
        onValueChange={itemValue => onSelectedValueChanged(itemValue)}>
        {props.all && <Select.Item
          label={"All grains"}
          value="all" />}
        {
          types.map((grainType) => {
            return <Select.Item
              key={grainType['value']}
              label={grainType['label']}
              value={grainType['value']} />
          })
        }
      </Select>
    </View>
  );
};