import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  FlatList,
  Text,
  Spinner,
  VStack,
  HStack,
  Divider,
  Center,
  Input,
  Button,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import * as UploadAction from 'src/redux/actions/dataset';
import * as JobActions from 'src/redux/actions/jobAction';
import * as GrainTypeAction from 'src/redux/actions/grainTypeAction';
import { useFocusEffect } from '@react-navigation/native';
import { IJobs } from 'src/models/Job';
import { IStore } from 'src/redux/reducers';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';
import { getGrainName } from 'src/utils/utils';
import DatasetListItem from 'src/components/listItem/DatasetListItem';
import { Dataset } from 'src/models/UploadImages';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { clearDataset } from 'src/redux/actions/dataset';
interface DatasetListingPageProps {
  navigation: NavigationProp<any>;
  route: RouteProp<any>;
}

const PAGE_SIZE = 10; // Number of datasets to fetch per page


const DatasetListingPage = ({ navigation, route }: DatasetListingPageProps) => {
  const dispatch = useDispatch();

  // Redux state selectors
  const { datasets, loading, nextKey } = useSelector((state: IStore) => state.dataset);
  const orgJobs: IJobs | null | undefined = useSelector(
    (store: IStore) => store.job.orgJobs.jobs
  );
  const orgId: string = useSelector(
    (store: IStore) => store.user.userProfile.response?.org.orgId
  ) ?? '';
  const grainTypes = useSelector(
    (store: IStore) => store.user.userProfile.response?.grains
  );

  // Local state
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDatasets, setFilteredDatasets] = useState<Dataset[]>([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true); // To manage initial loading spinner
  const firstRender = useRef(true);
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsInitialLoading(false);
    }, 3500);

    return () => clearTimeout(timeoutId); 
  }, []);

  useFocusEffect(
    useCallback(() => {
      dispatch(UploadAction.setIsExistingDataset(true));
      const fetchInitialDatasets = async () => {
        try {
          // dispatch(clearDataset(undefined, { source: 'dataset' }));
          dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: null }));
        } catch (error) {
          console.error('Error fetching initial datasets:', error);
        }
      };

      fetchInitialDatasets();
      if (!orgJobs) {
        dispatch(JobActions.orgJobs.request({ orgId }));
      }

      return () => {
        if (route.params?.refresh) {
          navigation.setParams({ refresh: undefined });
        }
      };
    }, [dispatch, orgJobs, route.params?.refresh, orgId, navigation])
  );
  const filterGrainId = useSelector(
    (store: IStore) => store.app.grainIdFilter
  ) || 'all';
  useEffect(() => {
    const filtered = datasets
      .filter(dataset => filterBySearchTerm(dataset) && filterByGrainId(dataset))
      .sort(sortByCreatedAt);

    setFilteredDatasets(filtered);
  }, [datasets, searchTerm, filterGrainId]);

  // Function to filter datasets
  const filterBySearchTerm = (dataset: Dataset) => {
    const lowerSearch = searchTerm.toLowerCase();
    const datasetID = dataset.datasetID ? dataset.datasetID.toLowerCase() : '';
    const username = dataset.username ? dataset.username.toLowerCase() : '';
    const grainName = getGrainName(grainTypes, dataset.grainId).toLowerCase();

    return (
      datasetID.includes(lowerSearch) ||
      username.includes(lowerSearch) ||
      grainName.includes(lowerSearch)
    );
  };

  useEffect(() => {
    if (route.params?.refresh) {
      dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: null }));
      navigation.setParams({ refresh: undefined });
    }
  }, [dispatch, navigation, route.params?.refresh]);
  const filterByGrainId = (dataset: Dataset) => {
    return filterGrainId === 'all' || dataset.grainId === filterGrainId;
  };

  const sortByCreatedAt = (a: Dataset, b: Dataset) => {
    return b.createdAt - a.createdAt;
  };

  // Function to load more datasets
  const loadMoreDatasets = useCallback(async () => {
    if (isFetchingMore || !nextKey) return;

    setIsFetchingMore(true);
    try {
      dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: nextKey }));
    } catch (error) {
      console.error('Error loading more datasets:', error);
    } finally {
      setIsFetchingMore(false);
    }
  }, [dispatch, isFetchingMore, nextKey]);

  const handleDatasetSelect = useCallback(
    async (datasetId: string) => {
    dispatch(UploadAction.setIsExistingDataset(true));
    dispatch(clearDataset());
    setTimeout(() => {
      const selectedDataset = datasets.find(dataset => dataset.datasetID === datasetId);

      if (selectedDataset) {
        dispatch(GrainTypeAction.setGrainId(selectedDataset.grainId));

        if (!datasetId.startsWith('dataset_')) {
          const selectedJobStatus = orgJobs?.Items.find(job => job.jobId === datasetId);
          if (selectedJobStatus) {
            dispatch(JobActions.jobStatus.success(selectedJobStatus));
            dispatch(JobActions.setJobId(datasetId));
            dispatch(JobActions.pollJobStatus.request(datasetId));
          }
          dispatch(UploadAction.setIsJob(true));
          dispatch(UploadAction.setId(datasetId));
          navigation.navigate('CombinedLabelingPage');
        } else {
          dispatch(UploadAction.setDatasetId(datasetId));
          dispatch(UploadAction.setIsJob(false));
          dispatch(UploadAction.setId(datasetId));
        }
      }
      }, 1000);
      navigation.navigate('CombinedLabelingPage');
    },
    [dispatch, navigation, orgJobs, datasets]
  );

  const handleUploadImages = useCallback(
    (datasetId: string) => {
      dispatch(UploadAction.setIsExistingDataset(true));
      dispatch(UploadAction.setDatasetId(datasetId));
      navigation.navigate('ImageUploadPage');
    },
    [dispatch, navigation]
  );

  const renderItem = useCallback(
    ({ item }: { item: Dataset }) => (
      <DatasetListItem
        item={item}
        handleDatasetSelect={handleDatasetSelect}
        handleUploadImages={handleUploadImages}
        getGrainName={(grainId) => getGrainName(grainTypes, grainId)}
      />
    ),
    [handleDatasetSelect, handleUploadImages, grainTypes]
  );

  if (isInitialLoading) {
    return (
      <Center flex={1} px="3">
        <Spinner accessibilityLabel="Loading datasets" />
      </Center>
    );
  }

  return (
    <VStack space={4} p={5} flex={1}>
      <Text fontSize="xl" fontWeight="bold">
        Available Datasets
      </Text>
      <HStack space={2}>
        <Input
          flex={1}
          placeholder="Search by Dataset ID, Username, or Grain Name"
          value={searchTerm}
          onChangeText={setSearchTerm}
        />
        <GrainTypeDropdown all />
      </HStack>
      <Divider />
      {filteredDatasets.length > 0 ? (
        <FlatList
          data={filteredDatasets}
          renderItem={renderItem}
          keyExtractor={item => item.datasetID}
          ListFooterComponent={nextKey ? (
            <Center py={4}>
              <Button onPress={loadMoreDatasets}>Load More</Button>
            </Center>
          ) : null}
          refreshing={loading}
          onRefresh={() => {
            dispatch(clearDataset(undefined, { source: 'dataset' }));
            dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: null }));
          }}
        />
      ) : (
        <Center flex={1}>
          <Text>Loading datasets..</Text>
        </Center>
      )}
    </VStack>
  );
};

export default DatasetListingPage;