// import React, { useEffect, useCallback, useState, useMemo } from 'react';
// import { FlatList } from 'react-native';
// import {
//   ScrollView,
//   Text,
//   Spinner,
//   VStack,
//   HStack,
//   Button,
//   Center,
//   Box,
//   Modal,
//   Input,
//   Select,
//   Switch,
// } from 'native-base';
// import { useSelector, useDispatch } from 'react-redux';
// import { IStore } from 'src/redux/reducers';
// import * as TrainingActions from 'src/redux/actions/trainingAction';
// import { TrainingJobInfo } from 'src/models/Training';
// import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';

// const TrainingJobDetailsPage = () => {
//   const dispatch = useDispatch();
//   const { trainingJobs, loading, error, currentTrainingJob } = useSelector(
//     (state: IStore) => state.training
//   );
//   const selectedGrainId = useSelector((store: IStore) => store.app.grainIdFilter) || 'all';

//   const [selectedJob, setSelectedJob] = useState<TrainingJobInfo | null>(null);
//   const [showModal, setShowModal] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [modelName, setModelName] = useState('resnet-18');
//   const [checkpointPath, setCheckpointPath] = useState('');
//   const [isErosionEnabled, setIsErosionEnabled] = useState(false);
//   const [isMaskingEnabled, setIsMaskingEnabled] = useState(false);
//   const [useEdgeDetection, setUseEdgeDetection] = useState(false);

//   // Fetch all training jobs on component mount
//   useEffect(() => {
//     dispatch(TrainingActions.fetchAllTrainingJobs.request());
//   }, [dispatch]);

//   useEffect(() => {
//     if (selectedJob) {
//       // Pre-populate fields with current values or defaults
//       const { updatedAttributes, modelName, checkpointPath, trainingConfig } = selectedJob;
//       console.log(updatedAttributes)
//       setModelName(updatedAttributes?.modelName || modelName || 'resnet-18');
//       setCheckpointPath(updatedAttributes?.checkpointPath || checkpointPath || '');
//       setIsErosionEnabled(
//         updatedAttributes?.trainingConfig?.is_erosion_enabled ||
//           trainingConfig?.is_erosion_enabled ||
//           false
//       );
//       setIsMaskingEnabled(
//         updatedAttributes?.trainingConfig?.is_masking_enabled ||
//           trainingConfig?.is_masking_enabled ||
//           false
//       );
//       setUseEdgeDetection(
//         updatedAttributes?.trainingConfig?.use_edge_detection ||
//           trainingConfig?.use_edge_detection ||
//           false
//       );
//     }
//   }, [selectedJob]);
  

//   // Update modal fields with current job details
//   useEffect(() => {
//     if (
//       currentTrainingJob &&
//       selectedJob &&
//       currentTrainingJob.trainingJobID === selectedJob.trainingJobID
//     ) {
//       setSelectedJob(currentTrainingJob);
//       setModelName(currentTrainingJob.modelName || 'resnet-18');
//       setCheckpointPath(currentTrainingJob.updatedAttributes?.checkpointPath || '');
//       setIsErosionEnabled(currentTrainingJob.updatedAttributes?.trainingConfig?.is_erosion_enabled || false);
//       setIsMaskingEnabled(currentTrainingJob.updatedAttributes?.trainingConfig?.is_masking_enabled || false);
//       setUseEdgeDetection(currentTrainingJob.updatedAttributes?.trainingConfig?.use_edge_detection || false);
//     }
//   }, [currentTrainingJob, selectedJob]);

//   const handleJobPress = useCallback((job: TrainingJobInfo) => {
//     setSelectedJob(job);
//     setShowModal(true);

//     // Dispatch action to fetch job details if needed
//     dispatch(TrainingActions.fetchTrainingJobDetails.request(job.trainingJobID));
//   }, [dispatch]);

//   const handleSubmitAttributes = async () => {
//     if (selectedJob) {
//       const updatedAttributes = {
//         ...selectedJob.updatedAttributes, // Include existing attributes
//         grainId: selectedGrainId,
//         modelName,
//         checkpointPath,
//         trainingConfig: {
//           ...selectedJob.updatedAttributes?.trainingConfig, // Preserve existing training config
//           is_erosion_enabled: isErosionEnabled,
//           is_masking_enabled: isMaskingEnabled,
//           use_edge_detection: useEdgeDetection,
//         },
//       };
  
//       try {
//         setIsUpdating(true);
  
//         // Dispatch the update action
//         await dispatch(
//           TrainingActions.updateTrainingJob.request({
//             trainingJobID: selectedJob.trainingJobID,
//             updatedAttributes,
//           })
//         );
  
//         // Refresh the list and close the modal
//         await dispatch(TrainingActions.fetchAllTrainingJobs.request());
//         setShowModal(false);
//         setSelectedJob(null);
//       } catch (error) {
//         console.error('Error updating training job:', error);
//       } finally {
//         setIsUpdating(false);
//       }
//     }
//   };
  
//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedJob(null); // Reset selected job when closing modal
//   };

//   // Filter training jobs based on selected grain type
//   const filteredTrainingJobs = useMemo(() => {
//     if (selectedGrainId === 'all') {
//       return trainingJobs;
//     } else {
//       return trainingJobs.filter((job) => job.grainId === selectedGrainId);
//     }
//   }, [trainingJobs, selectedGrainId]);

//   const renderItem = useCallback(
//     ({ item }: { item: TrainingJobInfo }) => (
//       <HStack
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//         p={2}
//         borderWidth={1}
//         borderColor="gray.200"
//         borderRadius={5}
//       >
//         <VStack>
//           <Text fontWeight="bold">Job ID: {item.trainingJobID}</Text>
//           <Text>Grain Type: {item.grainId}</Text>
//           <Text>Model: {item.modelName || 'N/A'}</Text>
//         </VStack>
//         <Button onPress={() => handleJobPress(item)}>View Details</Button>
//       </HStack>
//     ),
//     [handleJobPress]
//   );

//   if (loading) {
//     return (
//       <Center flex={1} px="3">
//         <Spinner accessibilityLabel="Loading training jobs" />
//         <Text mt={4}>Loading training jobs...</Text>
//       </Center>
//     );
//   }

//   if (error) {
//     return (
//       <Center flex={1} px="3">
//         <Text mt={4} color="red.500">
//           Error: {error}
//         </Text>
//       </Center>
//     );
//   }

//   return (
//     <ScrollView>
//       <VStack space={4} p={5}>
//         <Text fontSize="xl" fontWeight="bold">
//           Training Job Details
//         </Text>

//         <Box>
//           <Text mb={2}>Select Grain Type:</Text>
//           <GrainTypeDropdown all />
//         </Box>

//         {filteredTrainingJobs.length === 0 ? (
//           <VStack space={4} alignItems="center">
//             <Text>No training jobs available for the selected grain type.</Text>
//           </VStack>
//         ) : (
//           <FlatList
//             data={filteredTrainingJobs}
//             renderItem={renderItem}
//             keyExtractor={(item) => item.trainingJobID}
//           />
//         )}

//         {selectedJob && (
//         <Modal isOpen={showModal} onClose={closeModal}>
//         <Center flex={1}>
//         <Box p={5} bg="white" borderRadius="10" width="90%">
//           <ScrollView>
//             <Text fontSize="lg" fontWeight="bold">
//               Training Job Details
//             </Text>
//             <Text mt={2}>Job ID: {selectedJob.trainingJobID}</Text>
//             <Text>Grain Type: {selectedJob.grainId}</Text>
//             <Text>Model: {selectedJob.modelName || 'N/A'}</Text>

//             <VStack mt={4} space={2}>
//               <Text fontWeight="bold">Update Training Job Attributes:</Text>

//               {/* Model Name */}
//               <VStack>
//                 <Text>Model Name:</Text>
//                 <Select
//                   selectedValue={modelName}
//                   onValueChange={setModelName}
//                   placeholder="Select Model"
//                 >
//                   <Select.Item label="resnet-18" value="resnet-18" />
//                   <Select.Item label="resnet-50" value="resnet-50" />
//                 </Select>
//               </VStack>

//               {/* Checkpoint Path */}
//               <VStack>
//                 <Text>Checkpoint Path:</Text>
//                 <Input
//                   placeholder="Enter checkpoint path"
//                   value={checkpointPath}
//                   onChangeText={setCheckpointPath}
//                 />
//               </VStack>

//               {/* Is Erosion Enabled */}
//               <HStack alignItems="center" space={2}>
//                 <Text>Is Erosion Enabled:</Text>
//                 <Switch isChecked={isErosionEnabled} onToggle={setIsErosionEnabled} />
//               </HStack>

//               {/* Is Masking Enabled */}
//               <HStack alignItems="center" space={2}>
//                 <Text>Is Masking Enabled:</Text>
//                 <Switch isChecked={isMaskingEnabled} onToggle={setIsMaskingEnabled} />
//               </HStack>

//               {/* Use Edge Detection */}
//               <HStack alignItems="center" space={2}>
//                 <Text>Use Edge Detection:</Text>
//                 <Switch isChecked={useEdgeDetection} onToggle={setUseEdgeDetection} />
//               </HStack>

//               <Button mt={2} onPress={handleSubmitAttributes} isLoading={isUpdating}>
//                 Update Training Job
//               </Button>
//             </VStack>


//             <Button mt={4} onPress={closeModal}>
//               Close
//             </Button>
//           </ScrollView>
//         </Box>
//         </Center>
//         </Modal>
//         )}

//       </VStack>
//     </ScrollView>
//   );
// };

// export default TrainingJobDetailsPage;
// src/pages/TrainingJobDetailsPage.tsx

import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {
  ScrollView,
  Text,
  Spinner,
  VStack,
  HStack,
  Button,
  Center,
  Box,
  Modal,
  Input,
  Select,
  Switch,
  Checkbox,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import * as TrainingActions from 'src/redux/actions/trainingAction';
import * as TestingJobActions from 'src/redux/actions/testingAction';
import { TrainingJobInfo } from 'src/models/Training';
import { TestingJobInfo, CreateTestingJobRequest } from 'src/models/testingJob';
import GrainTypeDropdown from 'src/components/dropdown/GrainTypeDropdown';

const TrainingJobDetailsPage: React.FC = () => {
  const dispatch = useDispatch();

  // Training job state
  const {
    trainingJobs,
    loading: trainingLoading,
    error: trainingError,
    currentTrainingJob,
  } = useSelector((state: IStore) => state.training);

  // Testing job state
  const {
    testingJobs,
    currentTestingJob,
    loading: testingLoading,
    error: testingError,
  } = useSelector((state: IStore) => state.testing);

  const selectedGrainId = useSelector(
    (state: IStore) => state.app.grainIdFilter
  ) || 'all';

  // State variables for Training Job Modal
  const [selectedTrainingJob, setSelectedTrainingJob] = useState<TrainingJobInfo | null>(null);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modelName, setModelName] = useState('resnet-18');
  const [checkpointPath, setCheckpointPath] = useState('');
  const [isErosionEnabled, setIsErosionEnabled] = useState(false);
  const [isMaskingEnabled, setIsMaskingEnabled] = useState(false);
  const [useEdgeDetection, setUseEdgeDetection] = useState(false);

  // State variables for Creating Testing Job Modal
  const [showCreateTestingModal, setShowCreateTestingModal] = useState(false);
  const [selectedTrainingJobIDs, setSelectedTrainingJobIDs] = useState<string[]>([]);

  // State variables for Testing Job Details Modal
  const [showTestingJobDetailsModal, setShowTestingJobDetailsModal] = useState(false);

  // Fetch all training jobs on component mount
  useEffect(() => {
    dispatch(TrainingActions.fetchAllTrainingJobs.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(TestingJobActions.fetchAllTestingJobs.request());
  }, [dispatch]);
  // Update Training Job Modal fields when a training job is selected
  useEffect(() => {
    if (selectedTrainingJob) {
      const {
        updatedAttributes,
        modelName: jobModelName,
        checkpointPath: jobCheckpointPath,
        trainingConfig,
      } = selectedTrainingJob;
      setModelName(updatedAttributes?.modelName || jobModelName || 'resnet-18');
      setCheckpointPath(updatedAttributes?.checkpointPath || jobCheckpointPath || '');
      setIsErosionEnabled(
        updatedAttributes?.trainingConfig?.is_erosion_enabled ||
          trainingConfig?.is_erosion_enabled ||
          false
      );
      setIsMaskingEnabled(
        updatedAttributes?.trainingConfig?.is_masking_enabled ||
          trainingConfig?.is_masking_enabled ||
          false
      );
      setUseEdgeDetection(
        updatedAttributes?.trainingConfig?.use_edge_detection ||
          trainingConfig?.use_edge_detection ||
          false
      );
    }
  }, [selectedTrainingJob]);

  // Handle Training Job Item Press
  const handleTrainingJobPress = useCallback(
    (job: TrainingJobInfo) => {
      setSelectedTrainingJob(job);
      setShowTrainingModal(true);
      dispatch(TrainingActions.fetchTrainingJobDetails.request(job.trainingJobID));
    },
    [dispatch]
  );

  // Handle Updating Training Job Attributes
  const handleUpdateTrainingJob = async () => {
    if (selectedTrainingJob) {
      const updatedAttributes = {
        ...selectedTrainingJob.updatedAttributes,
        grainId: selectedGrainId,
        modelName,
        checkpointPath,
        trainingConfig: {
          ...selectedTrainingJob.updatedAttributes?.trainingConfig,
          is_erosion_enabled: isErosionEnabled,
          is_masking_enabled: isMaskingEnabled,
          use_edge_detection: useEdgeDetection,
        },
      };

      try {
        setIsUpdating(true);
        await dispatch(
          TrainingActions.updateTrainingJob.request({
            trainingJobID: selectedTrainingJob.trainingJobID,
            updatedAttributes,
          })
        );
        await dispatch(TrainingActions.fetchAllTrainingJobs.request());
        setShowTrainingModal(false);
        setSelectedTrainingJob(null);
      } catch (error) {
        console.error('Error updating training job:', error);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  // Handle Closing Training Job Modal
  const closeTrainingModal = () => {
    setShowTrainingModal(false);
    setSelectedTrainingJob(null);
  };

  // Handle Selecting/Deselecting Training Jobs for Testing Job
  const toggleTrainingJobSelection = (jobID: string) => {
    setSelectedTrainingJobIDs((prevSelected) => {
      if (prevSelected.includes(jobID)) {
        return prevSelected.filter((id) => id !== jobID);
      } else {
        return [...prevSelected, jobID];
      }
    });
  };

  // Handle Creating Testing Job
  const handleCreateTestingJob = async () => {
    if (selectedTrainingJobIDs.length === 0) {
      alert('Please select at least one training job.');
      return;
    }

    const request: CreateTestingJobRequest = {
      trainingJobIDs: selectedTrainingJobIDs,
      grainId: selectedGrainId,
    };

    try {
      await dispatch(TestingJobActions.createTestingJob.request(request));
      alert('Testing job created successfully.');
      setShowCreateTestingModal(false);
      setSelectedTrainingJobIDs([]);
      // Optionally, refresh the testing jobs list if you have a fetchAllTestingJobs action
      // dispatch(TestingJobActions.fetchAllTestingJobs.request());
    } catch (error) {
      console.error('Error creating testing job:', error);
      alert('Failed to create testing job.');
    }
  };

  // Handle Viewing Testing Job Details
  const handleViewTestingJob = useCallback(
    (testingJobID: string) => {
      dispatch(TestingJobActions.fetchTestingJobDetails.request(testingJobID));
      setShowTestingJobDetailsModal(true);
    },
    [dispatch]
  );

  // Close Testing Job Details Modal
  const closeTestingJobDetailsModal = () => {
    setShowTestingJobDetailsModal(false);
  };

  // Filter training jobs based on selected grain type
  const filteredTrainingJobs = useMemo(() => {
    if (selectedGrainId === 'all') {
      return trainingJobs;
    } else {
      return trainingJobs.filter((job) => job.grainId === selectedGrainId);
    }
  }, [trainingJobs, selectedGrainId]);

  // Render Training Job Item
  const renderTrainingJobItem = useCallback(
    ({ item }: { item: TrainingJobInfo }) => (
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        p={2}
        borderWidth={1}
        borderColor="gray.200"
        borderRadius={5}
      >
        <VStack>
          <Text fontWeight="bold">Job ID: {item.trainingJobID}</Text>
          <Text>Grain Type: {item.grainId}</Text>
          <Text>Model: {item.modelName || 'N/A'}</Text>
        </VStack>
        <Button onPress={() => handleTrainingJobPress(item)}>View Details</Button>
      </HStack>
    ),
    [handleTrainingJobPress]
  );

  // Render Testing Job Item
const renderTestingJobItem = useCallback(
  ({ item }: { item: TestingJobInfo }) => (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      p={2}
      borderWidth={1}
      borderColor="gray.200"
      borderRadius={5}
    >
      <VStack>
        <Text fontWeight="bold">Testing Job ID: {item.testingJobID}</Text>
      </VStack>
      <Button onPress={() => handleViewTestingJob(item.testingJobID)}>View Details</Button>
    </HStack>
  ),
  [handleViewTestingJob]
);


  if (trainingLoading || testingLoading) {
    return (
      <Center flex={1} px="3">
        <Spinner accessibilityLabel="Loading data" />
        <Text mt={4}>Loading...</Text>
      </Center>
    );
  }

  if (trainingError || testingError) {
    return (
      <Center flex={1} px="3">
        <Text mt={4} color="red.500">
          Error: {trainingError || testingError}
        </Text>
      </Center>
    );
  }

  return (
    <ScrollView>
      <VStack space={4} p={5}>
        <Text fontSize="xl" fontWeight="bold">
          Training Job Details
        </Text>

        <Box>
          <Text mb={2}>Select Grain Type:</Text>
          <GrainTypeDropdown all />
        </Box>

        {/* Training Jobs List */}
        {filteredTrainingJobs.length === 0 ? (
          <VStack space={4} alignItems="center">
            <Text>No training jobs available for the selected grain type.</Text>
          </VStack>
        ) : (
          <FlatList
            data={filteredTrainingJobs}
            renderItem={renderTrainingJobItem}
            keyExtractor={(item) => item.trainingJobID}
          />
        )}

        {/* Button to Open Create Testing Job Modal */}
        <Button onPress={() => setShowCreateTestingModal(true)}>
          Create Testing Job
        </Button>

        {/* Testing Jobs List */}
        <Text fontSize="lg" fontWeight="bold" mt={4}>
          Testing Jobs
        </Text>
        {testingJobs.length === 0 ? (
          <Text>No testing jobs available.</Text>
        ) : (
          <FlatList
            data={testingJobs}
            renderItem={renderTestingJobItem}
            keyExtractor={(item) => item.testingJobID}
          />
        )}


        {/* Modal for Training Job Details */}
        <Modal isOpen={showTrainingModal} onClose={closeTrainingModal}>
          <Center flex={1}>
            <Box p={5} bg="white" borderRadius="10" width="90%">
              <ScrollView>
                <Text fontSize="lg" fontWeight="bold">
                  Training Job Details
                </Text>
                {selectedTrainingJob && (
                  <>
                    <Text mt={2}>Job ID: {selectedTrainingJob.trainingJobID}</Text>
                    <Text>Grain Type: {selectedTrainingJob.grainId}</Text>
                    <Text>Model: {selectedTrainingJob.modelName || 'N/A'}</Text>

                    <VStack mt={4} space={2}>
                      <Text fontWeight="bold">Update Training Job Attributes:</Text>

                      {/* Model Name */}
                      <VStack>
                        <Text>Model Name:</Text>
                        <Select
                          selectedValue={modelName}
                          onValueChange={setModelName}
                          placeholder="Select Model"
                        >
                          <Select.Item label="resnet-18" value="resnet-18" />
                          <Select.Item label="resnet-50" value="resnet-50" />
                        </Select>
                      </VStack>

                      {/* Checkpoint Path */}
                      <VStack>
                        <Text>Checkpoint Path:</Text>
                        <Input
                          placeholder="Enter checkpoint path"
                          value={checkpointPath}
                          onChangeText={setCheckpointPath}
                        />
                      </VStack>

                      {/* Is Erosion Enabled */}
                      <HStack alignItems="center" space={2}>
                        <Text>Is Erosion Enabled:</Text>
                        <Switch
                          isChecked={isErosionEnabled}
                          onToggle={setIsErosionEnabled}
                        />
                      </HStack>

                      {/* Is Masking Enabled */}
                      <HStack alignItems="center" space={2}>
                        <Text>Is Masking Enabled:</Text>
                        <Switch
                          isChecked={isMaskingEnabled}
                          onToggle={setIsMaskingEnabled}
                        />
                      </HStack>

                      {/* Use Edge Detection */}
                      <HStack alignItems="center" space={2}>
                        <Text>Use Edge Detection:</Text>
                        <Switch
                          isChecked={useEdgeDetection}
                          onToggle={setUseEdgeDetection}
                        />
                      </HStack>

                      <Button
                        mt={2}
                        onPress={handleUpdateTrainingJob}
                        isLoading={isUpdating}
                      >
                        Update Training Job
                      </Button>
                    </VStack>
                  </>
                )}

                <Button mt={4} onPress={closeTrainingModal}>
                  Close
                </Button>
              </ScrollView>
            </Box>
          </Center>
        </Modal>

        {/* Modal for Creating Testing Job */}
        <Modal isOpen={showCreateTestingModal} onClose={() => setShowCreateTestingModal(false)}>
          <Center flex={1}>
            <Box p={5} bg="white" borderRadius="10" width="95%">
              <ScrollView>
                <Text fontSize="lg" fontWeight="bold">
                  Create Testing Job
                </Text>

                {/* Select Training Jobs */}
                <VStack mt={4} space={2}>
                  <Text>Select Training Jobs:</Text>
                  {filteredTrainingJobs.map((job) => (
                    <Checkbox
                      key={job.trainingJobID}
                      isChecked={selectedTrainingJobIDs.includes(job.trainingJobID)}
                      onChange={() => toggleTrainingJobSelection(job.trainingJobID)}
                      value={job.trainingJobID}
                      mb={2}
                    >
                      <Text>{job.modelName || job.trainingJobID}</Text>
                    </Checkbox>
                  ))}
                </VStack>

                {/* Select Grain Type */}
                <VStack mt={4} space={2}>
                  <Text>Select Grain Type:</Text>
                  <GrainTypeDropdown all />
                </VStack>

                <Button mt={4} onPress={handleCreateTestingJob}>
                  Create Testing Job
                </Button>
                <Button mt={2} variant="ghost" onPress={() => setShowCreateTestingModal(false)}>
                  Cancel
                </Button>
              </ScrollView>
            </Box>
          </Center>
        </Modal>

        {/* Modal for Testing Job Details */}
        <Modal
          isOpen={showTestingJobDetailsModal}
          onClose={closeTestingJobDetailsModal}
        >
          <Center flex={1}>
            <Box p={5} bg="white" borderRadius="10" width="95%">
              <ScrollView>
                <Text fontSize="lg" fontWeight="bold">
                  Testing Job Details
                </Text>
                {currentTestingJob ? (
                  <>
                    <Text mt={2}>Testing Job ID: {currentTestingJob.testingJobID}</Text>
                    <Text>Grain Type: {currentTestingJob.grainId}</Text>
                    <Text>
                      Training Jobs: {currentTestingJob.trainingJobIDs.join(', ')}
                    </Text>
                    <Text>S3 Path: {currentTestingJob.s3Path}</Text>
                    <Text>
                      Created At:{' '}
                      {new Date(currentTestingJob.createdAt * 1000).toLocaleString()}
                    </Text>
                  </>
                ) : (
                  <Text>No details available.</Text>
                )}
                <Button mt={4} onPress={closeTestingJobDetailsModal}>
                  Close
                </Button>
              </ScrollView>
            </Box>
          </Center>
        </Modal>
      </VStack>
    </ScrollView>
  );
};

export default TrainingJobDetailsPage;
