import { S3_BUCKET_URL } from 'src/constants';
import { ICsvImageData, IImageItem } from 'src/models/UploadImages';


  const parseCSV = (csvText: string): ICsvImageData[] => {
    const lines = csvText.split('\n');
    const headers = lines[0].split(',');
  
    return lines.slice(1).map((line) => {
      const values = line.split(',');
      return headers.reduce((obj, header, index) => {
        obj[header.trim()] = values[index];
        return obj;
      }, {} as ICsvImageData);
    });
  };
  

  const fetchCSVData = async (csvUrl: string): Promise<ICsvImageData[]> => {
    try {
      const response = await fetch(csvUrl);
      const csvText = await response.text();
      return parseCSV(csvText);
    } catch (error) {
      throw error;
    }
  };

export const processImages = async (isJob : boolean , jobData : any , jobDetails : any, images : IImageItem[], jobId : string, datasetId : string, isExistingDataset : boolean, isDataFetched : boolean) => {
    let newImages:IImageItem[] = [];
    if (!isExistingDataset && isJob && jobData && jobData.results?.indi_grain_csv && isDataFetched) {
        const csvData = await fetchCSVData(jobData.results.indi_grain_csv);
        newImages = await processImageData(csvData, jobId, true);
    } else if (isExistingDataset && isJob) {
        newImages = await processImageData(images, jobId);
    } else if (!isJob) {
        newImages = await processImageData(images, datasetId);
    }

    return newImages;
};

const processImageData = async (sourceImages: IImageItem[], sourceId: string, isCSVData = false) => {
  return Promise.all(
    sourceImages.map(async (img) => {
      const imagePath = isCSVData ? img.ImagePath : img.imagePath;
      return {
        imagePath: imagePath,
        label: img.label || "Good Grains",
        imageUrl: isCSVData
          ? `${S3_BUCKET_URL()}/output/${sourceId}/simgPy/${img.ImagePath}`
          : img.imageUrl || `${S3_BUCKET_URL()}/output/${sourceId}/simgPy/${img.imagePath}`,
      };
    })
  );
};

