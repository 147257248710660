import { all, fork } from "redux-saga/effects";

import job from "./job";
import upload from "./upload";
import notification from "./notification";
import analyse from "./analyse";
import organization from "./organization";
import grainType from "./grainType";
import report from "./report";
import app from "./app";
import user from "./user";
import login from "./login";
import productRelease from "./productRelease";
import userGuide from "./userGuide";
import dataset from "./dataset"
import trainingDataset from "./trainingDataset";
import testingJobSaga from "./testing";

export function* rootSaga() {
  yield all([fork(job),
  fork(upload),
  fork(grainType),
  fork(notification),
  fork(analyse),
  fork(organization),
  fork(app),
  fork(report),
  fork(login), 
  fork(user),
  fork(userGuide),
  fork(productRelease),
  fork(dataset),
  fork(trainingDataset),
  fork(testingJobSaga),
],
)
}
