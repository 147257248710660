import { customFetchWithRetry } from "./shared";
import FetchError from "./errors";

export const createMultiPartUpload = async (request: {
  object_keys: string[];
  action: string;
  numberOfParts: number;
}) => {
  try {
    const response = await customFetchWithRetry(`/ml/upload`, {
      method: "POST",
      body: JSON.stringify({ ...request, action: "createMultiPart" })
    });
    
    if (response.ok) {
      return response.json();
    }
    
    throw new FetchError(`Error creating multipart upload. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('Error creating multipart upload', e);
    throw new FetchError(e, 500);
  }
};

export const initiateMultipartUpload = async (key: string, contentType = "image/png") => {
  const response = await customFetchWithRetry('/ml/upload', {
    method: 'POST',
    body: JSON.stringify({
      action: 'initiate',
      object_keys: [key],
      numberOfParts: 1,
      contentType
    })
  });

  if (!response.ok) {
    throw new Error('Failed to initiate multipart upload');
  }

  const data = await response.json();
  return data.results[0];
};

export const uploadParts = async (presignedUrls: any[], file: File) => {
  if (!(file instanceof File)) {
    console.error('Invalid file object:', file);
    throw new Error('Invalid file object. Expected a File.');
  }

  const parts = [];
  const chunkSize = 5 * 1024 * 1024; // 5 MB per part

  for (const { url, partNumber } of presignedUrls) {
    const start = (partNumber - 1) * chunkSize;
    const end = Math.min(partNumber * chunkSize, file.size);
    const blob = file.slice(start, end);

    if (blob.size === 0) {
      console.error(`Blob is empty for part ${partNumber}. File size: ${file.size}`);
      throw new Error(`Failed to create blob for part ${partNumber}`);
    }

    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: blob,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Failed to upload part ${partNumber}. Status: ${response.status}, Error: ${errorText}`);
        throw new Error(`Failed to upload part ${partNumber}: ${response.statusText}`);
      }

      const etag = response.headers.get('ETag');
      if (!etag) {
        console.error(`No ETag received for part ${partNumber}`);
        throw new Error(`No ETag received for part ${partNumber}`);
      }

      parts.push({
        ETag: etag,
        PartNumber: partNumber,
      });
    } catch (error) {
      console.error(`Error uploading part ${partNumber}:`, error);
      throw error;
    }
  }

  return parts;
};

export const completeMultipartUpload = async (key: string, uploadId: string, parts: any[]) => {
  const response = await customFetchWithRetry('/ml/upload', {
    method: 'POST',
    body: JSON.stringify({
      action: 'complete',
      object_keys: [key],
      uploadId,
      parts
    })
  });

  if (!response.ok) {
    throw new Error('Failed to complete multipart upload');
  }

  return response.json();
};
