import React, { useCallback, memo } from 'react';
import { Image } from 'react-native';
import { Box, VStack, Text, Select, CheckIcon, FlatList } from 'native-base';
import { Dimensions } from 'react-native';
import { IImageItem, IRejectionMapping } from 'src/models/UploadImages';

const windowWidth = Dimensions.get('window').width;
const itemWidth = windowWidth / 2 - 20;  // Adjusted for margin and padding

interface ImageItemProps {
  item: IImageItem;
  currentLabel: string;
  rejectionMapping: IRejectionMapping;
  onLabelChange: (imagePath: string, label: string) => void;
}

const ImageItem: React.FC<ImageItemProps> = memo(
  ({ item, currentLabel, rejectionMapping, onLabelChange }) => {
    const imagePathDisplay = item.imagePath ? item.imagePath.split('/').pop() : 'Unknown';

    return (
      <Box
        width={itemWidth}
        borderWidth="1"
        borderColor="coolGray.200"
        borderRadius="md"
        p="2"
        mb="2"
        bg="white"
        margin={1}
      >
        <VStack space={2}>
          <Box width="100%" height={180} justifyContent="center" alignItems="center">
            <Image
              source={{ uri: item.imageUrl }}
              style={{ width: '100%', height: '100%' }}
              resizeMode="contain" // Ensures the image fits within the box without distortion
            />
          </Box>
          <Text fontSize="sm" bold numberOfLines={1}>
            {imagePathDisplay}
          </Text>
          <Select
            selectedValue={currentLabel}
            minWidth="100%"
            accessibilityLabel="Select Label"
            placeholder="Select Label"
            _selectedItem={{
              bg: 'cyan.600',
              endIcon: <CheckIcon size="5" />,
            }}
            mt="1"
            onValueChange={(value) => onLabelChange(item.imagePath, value)}
          >
            {Object.entries(rejectionMapping).map(([key, value]) => (
              <Select.Item key={key} label={value} value={value} />
            ))}
          </Select>
        </VStack>
      </Box>
    );
  }
);

interface ImageTableProps {
  images: Array<IImageItem>;
  changedLabels: IRejectionMapping;
  rejectionMapping: IRejectionMapping;
  onLabelChange: (imagePath: string, label: string) => void;
}

const ImageTable: React.FC<ImageTableProps> = memo(
  ({ images, changedLabels, rejectionMapping, onLabelChange }) => {
    const renderImageItem = useCallback(
      ({ item }: { item: IImageItem }) => {
        const currentLabel = changedLabels[item.imagePath] || rejectionMapping[item.label] || item.label;
        return (
          <ImageItem
            item={item}
            currentLabel={currentLabel}
            rejectionMapping={rejectionMapping}
            onLabelChange={onLabelChange}
          />
        );
      },
      [changedLabels, rejectionMapping, onLabelChange]
    );

    return (
      <FlatList
        data={images}
        renderItem={renderImageItem}
        keyExtractor={(item) => item.imagePath || item.id}
        ListEmptyComponent={() => <Text>No images to display.</Text>}
        extraData={changedLabels}
        numColumns={2}  // Ensure two columns
        columnWrapperStyle={{ justifyContent: 'space-between' }} // Ensure equal spacing between columns
      />
    );
  }
);

export default ImageTable;
