import { S3_BUCKET_URL } from "src/constants";
import { IJob } from "src/models"
import { downloadImageToFs, downloadPdfToFs } from "src/utils/download";
import * as api from "../../api";

export const downloadScannedImageFromId = async (jobId: string) => {
    const job = await api.getJob(jobId);
    await downloadScannedImage(job);
}

export const downloadScannedImage = async (job: IJob | null | undefined) => {
    if (job === undefined || job === null || job?.input_object_key === undefined) {
        return
    }

    if (job.additionalAttributes?.scannerId === 'DS1'
        || job.additionalAttributes?.scannerId === 'DOUBLE_SIDED') {
        const scannedImages = job.results?.scanned_images || [];

        for (let i = 0; i < scannedImages.length; i++) {
            const scannedImage = scannedImages[i];
            await downloadImageToFs(`${S3_BUCKET_URL()}/${scannedImage}`, `${job?.jobId}-${i}`)
        }
    } else {
        await downloadImageToFs(`${S3_BUCKET_URL()}/${job?.input_object_key}`, job?.jobId)
    }
}

export const downloadLabeledImage = async (job: IJob | null | undefined) => {
    if (job === undefined
        || job === null
        || job?.input_object_key === undefined
        || job.productName === "L10_GRAIN_ANALYSIS"
        || job.productName === "MANUAL_GRAIN_ANALYSIS") {
        return
    }

    await downloadImageToFs(`${S3_BUCKET_URL()}/${job?.results?.labeled_image}`, job?.jobId)
}

export const downloadReportPdf = async (job: IJob | null | undefined) => {
    if (job === undefined || job === null || job?.jobId === undefined) {
        return
    }

    const response = await api.getReport({
        report_type: "job_report",
        "report_params": {
            jobId: job?.jobId,
            deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Kolkata"
        }
    })

    if (response?.report_url === undefined) {
        return
    }

    await downloadPdfToFs(response?.report_url, job?.jobId)
}